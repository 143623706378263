<template>
  <v-app-bar
    id="app-bar"
    app
    color="white"
    height="75"
    class="custom-app-bar"
    style="
      position: fixed;
      z-index: 201;
      width: 100%;
      left: 0px;
      margin: 0px;
      background-color: red;
    "
    elevation="1"
  >
    <v-btn
      v-if="notLargScreen"
      class="mr-3"
      elevation="8"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon class="actionButton--text"> mdi-menu </v-icon>

      <!-- <v-icon v-else class="actionButton--text"> mdi-menu </v-icon> -->
    </v-btn>
    <div
      style="
        margin: 0px !important;
        padding: 0px !important;
        left: -20px !important;
      "
    >
      <img
        src="@/assets/img/logoDark.png"
        class=""
        style="width: 130px; margin-left: 0px; padding-left: 0px"
      />
    </div>
    <div
      style="
        background-color: lightgray;
        min-width: 1px;
        min-height: 70%;
        margin-left: 20px;
      "
    ></div>
    <p v-if="modeHomolg" class="mx-2" style="color: red">Homologação</p>
    <v-btn
      v-if="currentRouteName == 'Process'"
      text
      class="ml-2"
      min-width="0"
      @click="goBack"
    >
      <v-icon class="actionButton--text"> mdi-arrow-left </v-icon>Voltar
    </v-btn>

    <v-spacer />
    <div
      style="
        background-color: lightgray;
        min-width: 1px;
        min-height: 70%;
        margin-right: 20px;
      "
    ></div>

    <v-menu
      bottom
      min-width="200px"
      rounded
      offset-y
      v-model="menu"
      style="background-color: white"
    >
      <template v-slot:activator="{ on }" style="background-color: green">
        <button icon x-large v-on="on">
          <v-avatar color="primary" size="48">
            <span class="white--text text-h5">{{
              user?.username?.[0]?.toUpperCase()
            }}</span>
          </v-avatar>
          <span v-if="!mobile" class="black--text ml-2">{{
            user?.username
          }}</span>
        </button>
      </template>
      <v-card style="background-color: white; z-index: 2000" class="ma-0 pa-0">
        <v-list-item-content class="justify-center">
          <div class="mx-auto text-center">
            <v-avatar color="primary">
              <span class="white--text text-h5">{{ user?.username?.[0] }}</span>
            </v-avatar>
            <h4 v-if="!mobile" class="mt-4">{{ user?.username }}</h4>
            <p class="text-caption ma-2">
              {{ user?.email }}
            </p>
            <v-divider class="my-3"></v-divider>
            <v-btn depressed rounded text @click="goToAccount">
              Meus dados
            </v-btn>
            <v-divider class="my-3"></v-divider>
            <v-btn depressed rounded text @click="logout"> Sair </v-btn>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>

    <!-- <v-btn class="ml-2" min-width="0" text @click="logout">
      <v-icon class="actionButton--text">mdi-logout</v-icon> Sair
    </v-btn> -->
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    menu: false,
    notifications: [],
  }),

  mounted() {
    //alert("mounted");
    this.setDrawer(true);
  },
  computed: {
    ...mapState(["drawer"]),
    ...mapState({
      user: (state) => state.auth.user,
    }),
    modeHomolg() {
      return `${process.env.VUE_APP_ENV}` == "HOMOLOG";
    },
    notLargScreen() {
      // return this.$vuetify.breakpoint.xs;
      // return ["xs", "sm", "md"].includes(this.$vuetify.breakpoint.name);
      return ["xs", "sm", "md"].includes(this.$vuetify.breakpoint.name);
    },
    mobile() {
      // return this.$vuetify.breakpoint.xs;
      return ["xs"].includes(this.$vuetify.breakpoint.name);
    },

    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
    currentRouteName() {
      return this.$route.name;
    },
    modeHomolg() {
      return `${process.env.VUE_APP_ENV}` == "HOMOLOG";
    },
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),

    goToAccount() {
      this.$router.push("/account");
    },

    goBack() {
      this.$router.push("/dashboard");
    },
    async logout() {
      //this.$root.$emit("Spinner::show");
      await this.$firebase.auth().signOut();
      this.$store.commit("CLEAR_USER");
      this.$store.commit("CLEAR_TOKEN");
      this.$store.commit("CLEAR_ACCESS");
      // this.$session.clear();
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
.hover-button:hover {
  background-color: rgb(243, 239, 239);
}
.custom-app-bar {
  padding: 0px !important; /* Force padding to be 0px */
}
</style>
